<template>
  <div class="mb-3 p-3 card-gradient-inverted h-full" style="border-radius: 10px;">
    <Image
      :src="template.thumbnail"
      alt="Image"
      imageClass="shadow-4 md:w-32 w-12"
      imageStyle="border-radius: 10px !important;"
      preview
    />
    <div class="md:text-3xl text-2xl my-3 font-semibold">{{ template.name }}</div>
    <div class="md:text-2xl text-xl">
      {{
      template.description
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureCard",
  props: ["template"]
};
</script>
<style scoped>
.p-image-preview-indicator {
  border-radius: 10px !important;
}
.card-gradient-inverted {
  background: rgb(238, 238, 238);
  background: linear-gradient(
    0deg,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 10%,
    rgba(149, 37, 34, 1) 100%
  );
  border-radius: 10px;
  box-shadow: 10px -10px 20px -15px rgb(238 238 238);
}
</style>