<template>
  <div class="px-4 lg:px-6 h-full text-900 text-xl card-gradient">
    <div class="p-3 h-full flex flex-column">
      <h1 class="font-bold text-5xl mb-2 uppercase">{{ template.name }}</h1>
      <strong class="text-xl">{{ template.description }}</strong>
      <hr class="my-3 mx-0 border-top-1 border-none" />
      <strong>
        <span class="font-bold text-7xl">{{ template.price }}</span>
        <span class="ml-2 text-3xl font-medium">/ Tag</span>
      </strong>
      <hr class="my-3 mx-0 border-top-1 border-none" />
      <ul class="list-none p-0 m-0 flex-grow-1">
        <li class="mb-3">
          <strong class="mr-2">{{ template.plus }}</strong>
          <span v-if="template.plus">plus</span>
        </li>
        <li class="mb-3" v-for="feature in template.features" :key="feature">
          <i class="pi pi-check mr-2"></i>
          <span>{{ feature }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceCard",
  props: ["template"]
};
</script>

<style scoped>
.card-gradient {
  /* background: rgb(238, 238, 238);
  background: linear-gradient(
    0deg,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 20%,
    rgba(149, 37, 34, 1) 100%
  ); */
  background: rgb(149, 37, 34);
  background: linear-gradient(
    0deg,
    rgba(149, 37, 34, 1) 0%,
    rgba(238, 238, 238, 1) 74%,
    rgba(238, 238, 238, 1) 100%
  );
  border-radius: 10px;
  box-shadow: 10px -10px 20px -15px rgb(238 238 238);
}
</style>
