<template>
  <div class="p-4 md:px-6 lg:px-8">
    <h1 class="text-900 font-bold text-5xl mb-4 text-center uppercase">Wer bin i ?</h1>
    <div class="flex justify-content-around">
      <div :class="active === 0 ? 'w-6 card-gradient-inverted' : ''">
        <Button @click="active = 0" class="p-button-text highlight text-5xl md:text-7xl">
          <strong>1955</strong>
        </Button>
        <hr />
        <strong class="text-2xl">Baujahr</strong>
      </div>
      <div :class="active === 1 ? 'w-6 card-gradient-inverted' : ''">
        <Button @click="active = 1" class="p-button-text highlight text-5xl md:text-7xl">
          <strong>50</strong>
        </Button>
        <hr />
        <strong class="text-2xl">
          Jahre
          <br />Warsteiner
        </strong>
      </div>
      <div :class="active === 2 ? 'w-6 card-gradient-inverted' : ''">
        <Button @click="active = 2" class="p-button-text highlight text-5xl md:text-7xl">
          <strong>2021</strong>
        </Button>
        <hr />
        <strong class="text-2xl">Bayern</strong>
      </div>
    </div>
    <Accordion :activeIndex="active" class="my-6 md:m-8">
      <AccordionTab v-for="tab in tabs" :key="tab.title" :header="tab.title">
        <div class="md:mx-4">{{ tab.content }}</div>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "History",
  setup() {
    const active = ref(0);
    return {
      active,
      tabs: [
        {
          title: "Anfangszeit",
          content:
            "Geboren im Jahre 1955 als Daimler-Benz Feuerwehrlöschzug führte mein Weg vom Werk Mannheim nach Nordrhein-Westfalen zur Brauerei Warsteiner. Mit meinem 4.6 Liter Reihen-6-Zylinder und brachialen 100 PS aus dem Hause Mercedes-Benz verrichtete ich lange Jahre bis zu meinem Renteneintritt treue Dienste als Brandbekämpfer."
        },
        {
          title: "Umbau",
          content:
            "Aus Dankbarkeit behielt mich das deutschlandweit bekannte Brauhaus und stellte mich in neue Dienste. Mein Kleid blieb das Selbe, mein Innenleben wurde komplett verändert. Höchstprofessionell baute mich die hauseigene Werkstatt zu einem liebevoll gestaltetem Promotionfahrzeug mit Kühl- und Schankanlage, sowie anbaubarer Bar aus. Ich durfte weitere Jahre viele schöne Augenblicke im Namen der Brauerei erleben. Nach knapp 50 Jahren Firmenzugehörigkeit bei „Warsteiner“ war Anfang der 2000er Jahre dann aber auch für mich der Tag gekommen, sich endgültig von dem Traditionshaus zu verabschieden."
        },
        {
          title: "Zukunft",
          content:
            "Da ich allerdings noch lange nicht ans Aufhören denke und mich mit meinen knapp 25.000 originalen Kilometern, sowie dem für mein Alter sehr ansehnlichen Körperbau noch viel zu jung für eine Altersresidenz fühle, begab ich mich nocheinmal auf große Reise und wohne seit Oktober 2021 in einem idyllischen Örtchen namens Hindelmühle in der Oberpfalz im Bundesland der Biere Bayern."
        }
      ]
    };
  }
});
</script>

<style scoped>
.card-gradient {
  background: rgb(149, 37, 34);
  background: linear-gradient(
    0deg,
    rgba(149, 37, 34, 1) 0%,
    rgba(238, 238, 238, 1) 74%,
    rgba(238, 238, 238, 1) 100%
  );
  border-radius: 10px;
  box-shadow: 10px -10px 20px -15px rgb(238 238 238);
}

.card-gradient-inverted {
  background: rgb(238, 238, 238);
  background: linear-gradient(
    0deg,
    rgba(238, 238, 238, 1) 0%,
    rgba(238, 238, 238, 1) 10%,
    rgba(149, 37, 34, 1) 100%
  );
  border-radius: 10px;
  box-shadow: 10px -10px 20px -15px rgb(238 238 238);
}

.highlight {
  font-size: 4rem;
  text-align: center;
  color: black !important;
}
.highlight:focus {
  outline: none;
  box-shadow: none;
}
</style>
