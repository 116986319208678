<template>
  <footer class="mt-8">
    <div class="mb-4 text-xl">Gmacht mit ❤ in da Oberpfalz ©</div>
    <a class="text-xl underline cursor-pointer" @click="openPosition('bottom')"
      >Impressum</a
    >
    <Dialog
      header="Impressum"
      v-model:visible="displayPosition"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :position="position"
      :modal="true"
    >
      <Disclaimer></Disclaimer>
    </Dialog>
    <!-- <div>
      <div class="justify-content-center">
        <Button class="p-button-text">
          <a href="#" title="Impressum">Impressum</a>
        </Button>
        <Button class="p-button-text">
          <a href="#" title="Kontakt">Kontakt</a>
        </Button>
      </div>
    </div>-->
  </footer>
</template>

<script>
import { defineComponent, ref } from "vue";

import Disclaimer from "./Disclaimer.vue";

export default defineComponent({
  name: "Footer",
  components: {
    Disclaimer,
  },
  setup() {
    const displayPosition = ref(false);
    const position = ref("center");

    const openPosition = (pos) => {
      position.value = pos;
      displayPosition.value = true;
    };
    return {
      displayPosition,
      position,
      openPosition,
    };
  },
});
</script>
