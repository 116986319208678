<template>
  <div id="app">
    <div class="app-container">
      <Hero />
      <Image
        imageClass="w-full"
        :src="require('./assets/layered_peaks_inverted.svg')"
        alt="Image"
      />
      <Features />
      <Image
        imageClass="w-full"
        :src="require('./assets/layered_peaks.svg')"
        alt="Image"
        imageStyle="margin-bottom: -1rem;"
      />
      <Image
        imageClass="w-full"
        :src="require('./assets/layered_peaks_inverted.svg')"
        alt="Image"
      />
      <History />
      <h1 class="text-900 font-bold text-5xl -mb-4 text-center uppercase">
        Wos kost i ?
      </h1>
      <Image
        imageClass="w-full"
        imageStyle="margin-bottom: -1rem;"
        :src="require('./assets/layered_peaks.svg')"
        alt="Image"
      />
      <Pricing />
      <Image
        imageClass="w-full"
        :src="require('./assets/layered_peaks_inverted.svg')"
        alt="Image"
      />
      <Contact />
      <Footer />
      <Image
        imageClass="w-full"
        :src="require('./assets/layered_peaks.svg')"
        alt="Image"
      />
    </div>

    <Toast />
  </div>
</template>

<script>
import Hero from "./components/Hero.vue";
import Features from "./components/Features.vue";
import History from "./components/History.vue";
import Pricing from "./components/Pricing.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Hero,
    Features,
    //Features2,
    History,
    Pricing,
    Contact,
    Footer,
  },
};
</script>

<style>
@import "~primeflex/primeflex.css";
@font-face {
  font-family: AsiaTiger;
  src: url(./fonts/AsiaTiger/Asia-Tiger.woff);
}

#app {
  font-family: Futura;
  src: url(./fonts/Futura/Futura.ttf);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: #eee;
  /* background-image: url("./assets/red.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
  z-index: -10;
}

a {
  color: #2c3e50;
}

.app-container {
  text-align: center;
}

form {
  margin-top: 2em;
}

h1,
strong {
  font-family: "AsiaTiger";
  letter-spacing: 0.1em;
}

.p-accordion-header,
.p-accordion-header-link,
.p-accordion-content {
  font-family: Futura;
  src: url(./fonts/Futura/Futura.ttf);
  font-size: 1.2em;
  background-color: #eee !important;
  border: none !important;
  outline: none !important;
}
.p-component {
  font-family: Futura;
  src: url(./fonts/Futura/Futura.ttf);
  text-align: justify;
}
footer {
  text-align: center;
}
</style>
