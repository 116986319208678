<template>
  <div class="px-6 py-2 sm:px-8 md:px-8 lg:px-8 pricing-overview">
    <!-- <h1 class="text-900 font-bold text-5xl mb-4 text-center uppercase">Wos kost i ?</h1> -->
    <div class="grid my-4">
      <div
        class="col-12 lg:col-4"
        v-for="template in priceTemplates"
        :key="template"
      >
        <PriceCard :template="template" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import PriceCard from "./PriceCard.vue";

export default defineComponent({
  name: "Pricing",
  components: {
    PriceCard,
  },
  setup() {
    const priceTemplates = ref({
      basic: {
        name: "gloa",
        description: "Dringa",
        price: "349 €",
        plus: "",
        features: ["Zapfanlage", "Kühlanlage", "Bar / Theke"],
      },
      medium: {
        name: "middl",
        description: "Dringa, Muse & Banner",
        price: "499 €",
        plus: "Paket GLOA",
        features: ["BOSE Musikanlage", "individuell gestaltetes 2,5m Banner"],
      },
      advanced: {
        name: "grous",
        description: "Essen, Dringa, Muse & Banner",
        price: "599 €",
        plus: "Paket MIDDL",
        features: ["mobiler Spanferkelgrill"],
      },
    });

    return { priceTemplates };
  },
});
</script>
<style scoped>
.pricing-overview {
  background-color: #952522;
}
</style>
