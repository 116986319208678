import { createApp } from "vue";
import App from "./App.vue";

import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import OverlayPanel from "primevue/overlaypanel";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Image from "primevue/image";
import Timeline from "primevue/timeline";
import Divider from "primevue/divider";
import Dialog from "primevue/dialog";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);

app.component("InputText", InputText);
app.component("Button", Button);
app.component("Toast", Toast);
app.component("OverlayPanel", OverlayPanel);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Image", Image);
app.component("Timeline", Timeline);
app.component("Divider", Divider);
app.component("Dialog", Dialog);

app.mount("#app");
