<template>
  <div class="row-hero">
    <div class="video-logo">
      <img src="../assets/logo_transparent_white.svg" alt="moin" />
    </div>
    <video class="video" poster="../assets/red.png" autoplay playsinline muted loop>
      <source src="../assets/feierwong.mp4" type="video/webm" />
    </video>
    <div class="video-quote">
      <div class="chevron" />
      <div class="chevron" />
      <div class="chevron" />
      <span class="text">Da schau her</span>
    </div>
    <div class="custom-shape-divider-bottom-1651783575">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero"
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

.row-hero {
  height: 45rem;
  width: 100%;
  position: relative;
}
@media (min-width: 480px) {
  .row-hero {
    height: 25rem;
  }
}
@media (min-width: 720px) {
  .row-hero {
    height: 35rem;
  }
}

.video-logo {
  width: 12em;
  height: 12em;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.video-logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video-quote {
  width: 2em;
  height: 2em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 7em;
  z-index: 0;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.custom-shape-divider-bottom-1651783575 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1651783575 svg {
  position: relative;
  display: block;
  width: calc(132% + 1.3px);
  height: 50px;
}

.custom-shape-divider-bottom-1651783575 .shape-fill {
  fill: #952522;
}
</style>
