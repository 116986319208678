<template>
  <div class="p-4 md:px-6 lg:px-8 text-center">
    <div class="mb-3 font-bold text-3xl">
      <span class="text-900">Herndlmühlner</span>
      <span class="text-red-800">Feierwong</span>
    </div>
    <h1 class="text-900 font-bold text-5xl mb-4 text-center uppercase">Wos kon i ?</h1>
    <div class="grid">
      <div
        class="col-12 sm:col-3 md:col-3"
        v-for="template in featureTemplatesFirstRow"
        :key="template"
      >
        <FeatureCard :template="template" />
      </div>
      <div
        class="col-12 sm:col-4 md:col-4"
        v-for="template in featureTemplatesSecondRow"
        :key="template"
      >
        <FeatureCard :template="template" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import FeatureCard from "./FeatureCard.vue";

export default defineComponent({
  name: "Features",
  components: {
    FeatureCard
  },
  setup() {
    const featureTemplatesFirstRow = ref([
      {
        name: "Bar / Theke",
        description: "festhaltn !",
        pricing: "basic",
        icon: "pi pi-desktop",
        thumbnail: require("@/assets/features/bar_theke.jpg")
      },
      {
        name: "Zapfanlage",
        description: "jede Halbe kennt's !",
        pricing: "basic",
        icon: "pi pi-user",
        thumbnail: require("@/assets/features/zapfanlage.jpg")
      },
      {
        name: "Kühlung",
        description: "dass kold is !",
        pricing: "basic",
        icon: "pi pi-desktop",
        thumbnail: require("@/assets/features/kuehlung.jpg")
      },
      {
        name: "Markise",
        description: "unterstelln !",
        pricing: "basic",
        icon: "pi pi-desktop",
        thumbnail: require("@/assets/features/markise.jpg")
      }
    ]);

    const featureTemplatesSecondRow = ref([
      {
        name: "Feierleiter / Banner",
        description: "wia g'mocht !",
        pricing: "medium",
        icon: "pi pi-desktop",
        thumbnail: require("@/assets/features/feierleiter.jpg")
      },
      {
        name: "Fakklwong",
        description: "sauguad !",
        pricing: "advanced",
        icon: "pi pi-desktop",
        thumbnail: require("@/assets/features/fakklwong.jpg")
      },
      {
        name: "Musik",
        description: "laut wird's !",
        pricing: "medium",
        icon: "pi pi-desktop",
        thumbnail: require("@/assets/features/musikanlage.jpg")
      }
    ]);

    return { featureTemplatesFirstRow, featureTemplatesSecondRow };
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    }
  }
});
</script>
<style scoped>
.card {
  --card-gradient: rgba(0, 0, 0, 0.8);
  --card-gradient: #5e9ad9, #e271ad;
  --card-blend-mode: overlay;

  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);
  padding-bottom: 1rem;
  background-image: linear-gradient(
    var(--card-gradient),
    white max(9.5rem, 27vh)
  );
  overflow: hidden;

  img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: cover;
    max-height: max(10rem, 30vh);
    mix-blend-mode: var(--card-blend-mode);

    /* ~ * {
      margin-left: 1rem;
      margin-right: 1rem;
    } */
  }

  img:hover,
  img:focus-within {
    --card-gradient: #24a9d5 max(8.5rem, 20vh);
  }
}

/* Additional demo display styles */
* {
  box-sizing: border-box;
}

body {
  display: grid;
  place-content: center;
  justify-items: center;
  min-height: 100vh;
  margin: 0;
  padding: 1rem;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  color: #444;
  background-color: #e1faf1;
}

.card h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.card a {
  color: inherit;
}

.card-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
  grid-gap: 1.5rem;
  max-width: 100vw;
  width: 120ch;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
