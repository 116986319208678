<template>
  <div>
    <h1 class="text-900 font-bold text-5xl mb-4 text-center uppercase">
      Wei greigst mi ?
    </h1>
    <div class="grid col-12 justify-content-center flex">
      <ul class="list-none">
        <Image
          :src="require('../assets/contact.png')"
          alt="Image"
          height="200"
          imageClass="border-round shadow-2"
          preview
        />
      </ul>
      <Divider class="" layout="vertical" />
      <ul class="list-none flex flex-column align-items-start">
        <li>
          <i class="pi pi-phone" />
          <a href="tel:+4915142328003" class="ml-2 text-xl">+4915142328003</a>
        </li>
        <li>
          <i class="pi pi-envelope" />
          <a
            href="mailto:info@feierwong.de?subject=Anfrage Herndlmühlner Feierwong"
            class="ml-2 text-xl"
            >info@feierwong.de</a
          >
        </li>
        <li>
          <i class="pi pi-map-marker" />
          <span class="ml-2 text-xl">Hindelmühle 2</span>
          <br />
          <span class="ml-2 text-xl">93491 Stamsried</span>
        </li>
        <li>
          <i class="pi pi-instagram" />
          <a
            href="https://www.instagram.com/feierwong.bayern/"
            target="_blank"
            class="ml-2 text-xl"
            >feierwong-bayern</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Contact",
});
</script>

<style scoped>
li {
  margin: 1em;
}
</style>
